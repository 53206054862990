document.querySelectorAll('.accordion-header').forEach(header => {
  header.addEventListener('click', () => {
    const accordionItem = header.parentElement;

    if (accordionItem.classList.contains('active')) {
      accordionItem.classList.remove('active');
    } else {
      document.querySelectorAll('.accordion-item.active').forEach(activeItem => {
        activeItem.classList.remove('active');
      });
      accordionItem.classList.add('active');
    }
  });
});
